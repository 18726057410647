import styled from 'styled-components';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { adaptive } from '../../componentUI/screen';

export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  gap: 8px;

  border: 2px solid ${COLOR_MAP.accent.gold}40;
  border-radius: 6px;
`;

export const TitleWrapper = styled.div<{ isSelect: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 8px;

  cursor: ${({ isSelect }) => (isSelect ? 'pointer' : '')};
`;

export const InstructionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  ${adaptive.maxWidth.mobile} {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const UnorderedList = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 18px;

  & li {
    display: flex;
    position: relative;
  }

  & li:before {
    position: absolute;
    left: -9px;
    content: '•';
    font-size: 10pt;
  }
`;
