import Modal from 'react-modal';
import React from 'react';
import styled from 'styled-components';
import { Title, TITLE_SIZE } from '../componentUI/simple/Title';
import { Text } from '../componentUI/simple/Text';
import { SecondButton } from '../componentUI/simple/Button';
import { SecondDangerButton } from '../componentUIOld/simple/Button/SecondDangerButton';
import { truthfulAdaptive } from '../componentUI/screen';

type Props = {
  isOpenModal: boolean;
  goBackCallback: () => void;
  rejectOrderCallback: () => void;
};

const styleModal: { content: React.CSSProperties } = { content: { outline: 'none' } };

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 520px;
  padding: 14px 14px 20px 14px;
  background-color: #ffffff;
  gap: 15px;

  ${truthfulAdaptive.maxWidth.tablet} {
    max-width: 380px;
  }

  ${truthfulAdaptive.maxWidth.mobile} {
    max-width: 310px;
  }
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 18px;
`;

export const CancelOrder = ({ isOpenModal, goBackCallback, rejectOrderCallback }: Props) => {
  return (
    <Modal
      isOpen={isOpenModal}
      contentLabel="Example Modal"
      overlayClassName="overlayStyle"
      className="contentStyle"
      style={styleModal}
    >
      <ModalWrapper>
        <CloseButtonWrapper>
          <svg
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: 'pointer' }}
            onClick={goBackCallback}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.78239 1.03239C8.83584 0.979007 8.87825 0.91562 8.9072 0.845848C8.93615 0.776075 8.95107 0.701284 8.95112 0.625743C8.95117 0.550203 8.93633 0.475393 8.90747 0.405585C8.8786 0.335777 8.83627 0.272337 8.78289 0.218889C8.72951 0.165441 8.66612 0.123031 8.59635 0.0940804C8.52658 0.0651295 8.45178 0.0502047 8.37624 0.0501583C8.3007 0.0501118 8.22589 0.0649447 8.15608 0.0938098C8.08628 0.122675 8.02284 0.165007 7.96939 0.21839L4.50039 3.68739L1.03239 0.21839C0.924447 0.110446 0.778044 0.0498047 0.62539 0.0498047C0.472735 0.0498047 0.326333 0.110446 0.21839 0.21839C0.110446 0.326333 0.0498047 0.472735 0.0498047 0.62539C0.0498047 0.778044 0.110446 0.924447 0.21839 1.03239L3.68739 4.50039L0.21839 7.96839C0.164942 8.02184 0.122544 8.08529 0.0936184 8.15512C0.0646925 8.22496 0.0498047 8.2998 0.0498047 8.37539C0.0498047 8.45098 0.0646925 8.52582 0.0936184 8.59566C0.122544 8.66549 0.164942 8.72894 0.21839 8.78239C0.326333 8.89033 0.472735 8.95098 0.62539 8.95098C0.700976 8.95098 0.775823 8.93609 0.845656 8.90716C0.91549 8.87823 0.978942 8.83584 1.03239 8.78239L4.50039 5.31339L7.96939 8.78239C8.07733 8.8902 8.22368 8.95071 8.37624 8.95062C8.5288 8.95053 8.67508 8.88983 8.78289 8.78189C8.8907 8.67395 8.95121 8.5276 8.95112 8.37504C8.95103 8.22248 8.89033 8.0762 8.78239 7.96839L5.31339 4.50039L8.78239 1.03239Z"
              fill="#999999"
            />
          </svg>
        </CloseButtonWrapper>
        <Title size={TITLE_SIZE.h5}>This order will be cancelled</Title>
        <Text>
          Unfortunately, if you are unable to provide
          <br /> this photo, your order will be cancelled.
        </Text>
        <Text>Do you wish to proceed with cancellation?</Text>
        <ButtonWrapper>
          <SecondButton onClick={goBackCallback}>&nbsp;Go&nbsp;back&nbsp;&nbsp;</SecondButton>
          <SecondDangerButton onClick={rejectOrderCallback}>Cancel&nbsp;order</SecondDangerButton>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  );
};
