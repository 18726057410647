import React from 'react';

/* eslint-disable max-len */

const CameraIcon = ({ size = '24' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.4142 20.4142C22.7893 20.0391 23 19.5304 23 19V8C23 7.46957 22.7893 6.96086 22.4142 6.58579C22.0391 6.21071 21.5304 6 21 6H17L15 3H9L7 6H3C2.46957 6 1.96086 6.21071 1.58579 6.58579C1.21071 6.96086 1 7.46957 1 8V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H21C21.5304 21 22.0391 20.7893 22.4142 20.4142Z"
      stroke="#020202"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
      stroke="#020202"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ChevronUpIcon = ({ size = '20' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 15L12 9L6 15" stroke="#020202" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ChevronDownIcon = ({ size = '20', color = '#020202' }: { size?: string; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 7.5L10 12.5L15 7.5" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ArrowUpLeftIcon = ({ size = '24' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 17L7 7" stroke="#020202" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 17V7H17" stroke="#020202" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ModelImageIcon = ({ size = '80' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11231_79130)">
      <rect width="80" height="80" fill="#CBAD73" fillOpacity="0.2" />
      <circle cx="61" cy="19" r="9" fill="#D1C7B1" />
      <path
        d="M25.0474 31.5C23.8927 29.5 21.006 29.5 19.8513 31.5L-9.5936 76.5C-10.7483 78.5 -9.30492 81 -6.99552 81H84.3501C86.7869 81 88.2071 78.2486 86.7956 76.2623L64.8948 45.4415C63.6989 43.7584 61.1998 43.7584 60.0039 45.4415L47.0697 63.6437L25.0474 31.5Z"
        fill="#D1C7B1"
      />
    </g>
    <defs>
      <clipPath id="clip0_11231_79130">
        <rect width="80" height="80" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const OtherModelImageIcon = ({ size = '80' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11231_79272)">
      <rect width="80" height="80" fill="#CBAD73" fillOpacity="0.2" />
      <path
        d="M26 40C26 43.3135 23.3145 46 20 46C16.6855 46 14 43.3135 14 40C14 36.6865 16.6855 34 20 34C23.3145 34 26 36.6865 26 40Z"
        fill="#D1C7B1"
      />
      <path
        d="M46 40C46 43.3135 43.3145 46 40 46C36.6855 46 34 43.3135 34 40C34 36.6865 36.6855 34 40 34C43.3145 34 46 36.6865 46 40Z"
        fill="#D1C7B1"
      />
      <path
        d="M60 46C63.3145 46 66 43.3135 66 40C66 36.6865 63.3145 34 60 34C56.6855 34 54 36.6865 54 40C54 43.3135 56.6855 46 60 46Z"
        fill="#D1C7B1"
      />
    </g>
    <defs>
      <clipPath id="clip0_11231_79272">
        <rect width="80" height="80" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const NotSureModelImageIcon = ({ size = '80' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11231_79277)">
      <rect x="0.5" width="80" height="80" fill="#CBAD73" fillOpacity="0.2" />
      <path
        d="M37.58 46.14C37.58 44.74 37.8 43.5 38.24 42.42C38.72 41.34 39.3 40.38 39.98 39.54C40.7 38.7 41.46 37.92 42.26 37.2C43.06 36.48 43.8 35.8 44.48 35.16C45.2 34.48 45.78 33.78 46.22 33.06C46.7 32.34 46.94 31.54 46.94 30.66C46.94 29.22 46.34 28.06 45.14 27.18C43.98 26.3 42.42 25.86 40.46 25.86C38.58 25.86 36.9 26.26 35.42 27.06C33.94 27.82 32.72 28.9 31.76 30.3L24.5 26.04C26.1 23.6 28.32 21.66 31.16 20.22C34 18.74 37.44 18 41.48 18C44.48 18 47.12 18.44 49.4 19.32C51.68 20.16 53.46 21.4 54.74 23.04C56.06 24.68 56.72 26.7 56.72 29.1C56.72 30.66 56.48 32.04 56 33.24C55.52 34.44 54.9 35.48 54.14 36.36C53.38 37.24 52.56 38.06 51.68 38.82C50.84 39.58 50.04 40.32 49.28 41.04C48.52 41.76 47.88 42.52 47.36 43.32C46.88 44.12 46.64 45.06 46.64 46.14H37.58ZM42.14 61.2C40.46 61.2 39.08 60.66 38 59.58C36.92 58.5 36.38 57.22 36.38 55.74C36.38 54.22 36.92 52.96 38 51.96C39.08 50.92 40.46 50.4 42.14 50.4C43.86 50.4 45.24 50.92 46.28 51.96C47.36 52.96 47.9 54.22 47.9 55.74C47.9 57.22 47.36 58.5 46.28 59.58C45.24 60.66 43.86 61.2 42.14 61.2Z"
        fill="#D1C7B1"
      />
    </g>
    <defs>
      <clipPath id="clip0_11231_79277">
        <rect x="0.5" width="80" height="80" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const FlagIcon = ({ size = '24' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 14C5 14 4 15 4 15V3C4 3 5 2 8 2C11 2 13 4 16 4C19 4 20 3 20 3V15C20 15 19 16 16 16C13 16 11 14 8 14Z"
      stroke="#020202"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4 22V15" stroke="#020202" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const DoubleCheck = ({ size = '16', color = '#CBAD73' }: { size?: string; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6667 4.66675L8.15237 11.3334L7.54224 10.709"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6667 4.66675L4.25004 11.3334L1.33337 8.30311"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export {
  ArrowUpLeftIcon,
  CameraIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DoubleCheck,
  FlagIcon,
  ModelImageIcon,
  NotSureModelImageIcon,
  OtherModelImageIcon,
};
