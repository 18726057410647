import React from 'react';

import styled from 'styled-components';
import { PropsButton } from './types';
import { commonStyles } from './utils';
import { BUTTON_SIZE, SIZE_STYLE } from './constants';
import { supportHover } from '../../screen';
import { COLOR_MAP } from '../../colorsMap';

const ButtonComponent = styled.button<PropsButton>`
  ${commonStyles};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0;

  border: none;
  background-color: transparent;

  ${({ size }) => SIZE_STYLE[size]};
  font-family: Montserrat, sans-serif;
  color: ${({ colorsMap }) => colorsMap?.clickable};

  user-select: none;

  & path {
    stroke: ${({ colorsMap }) => colorsMap?.clickable};
  }

  ${supportHover} {
    &:hover {
      color: ${({ colorsMap }) => colorsMap?.clickableHover};
      & path {
        stroke: ${({ colorsMap }) => colorsMap?.clickableHover};
      }
    }
  }

  &:active {
    color: ${({ colorsMap }) => colorsMap?.clickablePressed};
    & path {
      stroke: ${({ colorsMap }) => colorsMap?.clickablePressed};
    }
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover {
    color: ${({ colorsMap }) => colorsMap?.clickableDisabled};

    cursor: not-allowed;
    & path {
      stroke: ${({ colorsMap }) => colorsMap?.clickableDisabled};
    }
  }
`;

const DEFAULT_COLOR_MAP = {
  clickable: COLOR_MAP.controls.branded.clickable,
  clickableHover: COLOR_MAP.controls.branded.clickableHover,
  clickablePressed: COLOR_MAP.controls.branded.clickablePressed,
  clickableDisabled: COLOR_MAP.controls.branded.clickableDisabled,
};

export const BrandedButton = ({
  size = BUTTON_SIZE.small,
  isStretch = false,
  disabled = false,
  children,
  colorsMap = DEFAULT_COLOR_MAP,
  ...props
}: Partial<PropsButton>) => {
  return (
    <ButtonComponent size={size} isStretch={isStretch} disabled={disabled} colorsMap={colorsMap} {...props}>
      {typeof children === 'string' ? children : children}
    </ButtonComponent>
  );
};
