export const BUTTON_SIZE = {
  medium: 'medium',
  small: 'small',
  extraSmall: 'extraSmall',
} as const;

export const SIZE_STYLE: Record<keyof typeof BUTTON_SIZE, string> = {
  medium: 'font-size: 18px; line-height: 27px;',
  small: 'font-size: 16px; line-height: 22px;',
  extraSmall: 'font-size: 14px; line-height: 18px;',
};

export const BUTTON_H_ALIGN = {
  left: 'left',
  right: 'right',
  center: 'center',
} as const;

export const H_ALIGN_STYLE: Record<keyof typeof BUTTON_H_ALIGN, string> = {
  left: 'justify-content: flex-start; padding-left: 0;',
  right: 'justify-content: flex-end; padding-right: 0;',
  center: 'justify-content: center;',
};
