export const TITLE_SIZE = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
} as const;

export const TITLE_WEIGHT = {
  bold: '600',
  normal: '500',
} as const;

export const SIZE_STYLE: Record<keyof typeof TITLE_SIZE, string> = {
  h1: 'font-size: 40px; line-height: 56px;',
  h2: 'font-size: 32px; line-height: 45px;',
  h3: 'font-size: 28px; line-height: 36px;',
  h4: 'font-size: 24px; line-height: 29px;',
  h5: 'font-size: 20px; line-height: 24px;',
  h6: 'font-size: 18px; line-height: 22px;',
};

export const SIZE_STYLE_MOBILE: Record<keyof typeof TITLE_SIZE, string> = {
  h1: 'font-size: 30px; line-height: 36px;',
  h2: 'font-size: 28px; line-height: 34px;',
  h3: 'font-size: 26px; line-height: 31px;',
  h4: 'font-size: 24px; line-height: 29px;',
  h5: 'font-size: 20px; line-height: 24px;',
  h6: 'font-size: 18px; line-height: 22px;',
};
