export const BUTTON_SIZE = {
  big: 'big',
  medium: 'medium',
  small: 'small',
  extraSmall: 'extraSmall',
} as const;

export const PADDING: Record<keyof typeof BUTTON_SIZE, string> = {
  big: '13px 50px',
  medium: '11px 39px',
  small: '9px 32px',
  extraSmall: '7px 27px',
};

export const PADDING_SECONDARY: Record<keyof typeof BUTTON_SIZE, string> = {
  big: '13px 50px',
  medium: '11px 39px',
  small: '9px 32px',
  extraSmall: '7px 27px',
};

export const SIZE_STYLE = {
  big: 'font-size: 28px; line-height: 40px;',
  medium: 'font-size: 23px; line-height: 32px;',
  small: 'font-size: 18px; line-height: 25px;',
  extraSmall: 'font-size: 14px; line-height: 20px;',
};
