export const supportHover = '@media (any-hover: hover)';

export const breakpoints = {
  mobile: 319.99,
  tablet: 599.99,
  desktopS: 899.99,
  desktopSNew: 1023.99,
  desktop: 1199.99,
  desktop1440: 1439.99,
  desktop1600: 1599.99,
  mega: 1919.99,
} as const;

export const adaptive = {
  maxWidth: {
    mobile: `@media (max-width: ${breakpoints.tablet}px)`,
    desktopS: `@media (max-width: ${breakpoints.desktopS}px)`,
    desktop: `@media (max-width: ${breakpoints.desktop}px)`,
  },
  minWidth: {
    tablet: `@media (min-width: ${breakpoints.tablet}px)`,
    desktop: `@media (min-width: ${breakpoints.desktop}px)`,
    mega: `@media (min-width: ${breakpoints.mega}px)`,
  },
};

export const truthfulAdaptive = {
  maxWidth: {
    mobile: `@media (max-width: ${breakpoints.tablet}px)`,
    tablet: `@media (max-width: ${breakpoints.desktopS}px)`,
    tabletNew: `@media (max-width: ${breakpoints.desktopSNew}px)`,
    desktopS: `@media (max-width: ${breakpoints.desktop}px)`,
    desktop: `@media (max-width: ${breakpoints.desktop1440}px)`,
    desktop1440: `@media (max-width: ${breakpoints.desktop1600}px)`,
    desktop1600: `@media (max-width: ${breakpoints.mega}px)`,
  },
  minWidth: {
    tablet: `@media (min-width: ${breakpoints.tablet}px)`,
    desktopS: `@media (min-width: ${breakpoints.desktopS}px)`,
    desktop: `@media (min-width: ${breakpoints.desktop}px)`,
    desktop1440: `@media (min-width: ${breakpoints.desktop1440}px)`,
    desktop1600: `@media (min-width: ${breakpoints.desktop1600}px)`,
    mega: `@media (min-width: ${breakpoints.mega}px)`,
  },
};
