export const COLOR_MAP = {
  accent: {
    blue: '#6468CA',
    green: '#6BCBA9',
    red: '#DE4141',
    orange: '#F46C20',
    gold: '#CBAD73',
    lightGrey: '#DDDDDD',
    lightBlue: '#2976EA',
    lighterBlue: '#CEE9F1',
  },
  scroll: {
    grey: '#D9D9D9',
    greyBrown: '#7C7059',
  },
  background: {
    white: '#FFFFFF',
    darkGrey: '#1C1D21',
    lightGrey: '#F8F8F8',
    brandColor: '#CBAD73',
    SnowWhite: '#F5EFE3',
    brandColorSnow: '#CBAD7333',
  },
  text: {
    black: '#000000',
    darkGrey: '#666666',
    grey: '#AAAAAA',
    lightGrey: '#DDDDDD',
    whiteDisabled: '#FFFFFF19',
    white: '#FFFFFF',
    brandColor: '#CBAD73',
    orange: '#F46C20',
    lightBlue: '#2976EA',
  },
  controls: {
    placeholder: '#888888',
    normal: '#020202',
    disabled: '#777777',
    textAndIcon: '#FFFFFF',
    fillNormal: '#FFFFFF',
    clickableNormal: '#CBAD73',
    clickableHover: '#B09767',
    clickablePressed: '#B4904D',
    clickableDisabled: '#DCD0B9',
    strokeNormal: '#C8C8C8',
    strokeHover: '#B4B4B4',
    strokeActive: '#888888',

    dark: {
      clickable: '#020202',
      clickableHover: '#888888',
      clickablePressed: '#666666',
      clickableDisabled: '#DDDDDD',
    },
    branded: {
      clickable: '#CBAD73',
      clickableHover: '#B09767',
      clickablePressed: '#B4904D',
      clickableDisabled: '#DCD0B9',
    },
    light: {
      clickable: '#FFFFFF',
      clickableHover: '#EEEEEE',
      clickablePressed: '#CFCFCF',
      clickableDisabled: '#7E7E7E',
    },
    notification: {
      clickable: '#F5EFE3',
    },
  },
};
