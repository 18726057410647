export type ResponseGetPhotoList = {
  index_back_name: string;
  index: string;
  url: string;
  icon_url?: string;
  acceptable?: boolean;
  problem_description?: string | null;
  date_create?: number;
  photo_id?: number;
  problem?: string;
  url_photo_submitted?: string;
  zendesk_url?: string;
  comment?: string;
  image_samples?: string[];
  photo_marked?: string | null;
};

export type ResponseGetPhoto = {
  indexes: Array<ResponseGetPhotoList>;
  verification_info: {
    client_id: string;
    client_name: string;
  };
};

// полный тип тут - https://uploadcare.com/api-refs/upload-api/#tag/Upload/operation/fileUploadInfo
export type PhotoInfo = {
  file_id: string;
};

export enum Status {
  Loading,
  Done,
  Error,
  None,
  Reject,
  NoPhotos,
  NoId,
}
