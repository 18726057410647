import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

type Props = React.HTMLAttributes<HTMLElement> & {
  url: string;
  width: number;
  height: number;
  alt?: string;
};

const Loader = styled.div<{ height: number; width: number }>`
  ${({ width }) => width && `width: ${width - 1}px;`}
  ${({ height }) => height && `height: ${height - 1}px;`}
  border: 1px rgba(0,0,0,0) dashed;
`;

export const Picture = ({ url, width, alt, height, ...props }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [img, setImg] = useState<HTMLImageElement>();

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setIsLoading(false);
      setImg(image);
    };
    image.src = url;
  }, []);

  if (isLoading) {
    return <Loader width={width} height={height} {...props} />;
  }

  return <img src={img?.src} alt={alt} width={width} height={height} style={{ display: 'block' }} {...props} />;
};
