import React from 'react';

import styled from 'styled-components';
import { PropsButtonV2 } from './types';
import { buttonTextStyle, buttonTextStyleRegular, commonStyles } from './utils';
import { BUTTON_H_ALIGN, BUTTON_SIZE, H_ALIGN_STYLE, SIZE_STYLE } from './constants';
import { supportHover } from '../../screen';
import { COLOR_MAP } from '../../colorsMap';

const ButtonComponent = styled.button<PropsButtonV2>`
  ${commonStyles};
  ${({ isRegular }) => (isRegular ? buttonTextStyleRegular : buttonTextStyle)};
  ${({ size }) => SIZE_STYLE[size]};
  display: flex;
  padding: ${({ isNoPaddings }) => (isNoPaddings ? '0 10px' : '10px')};
  ${({ horizontalAlign }) => H_ALIGN_STYLE[horizontalAlign]};
  align-items: center;
  gap: 4px;

  border: 1px solid #cbad73;
  border-radius: 2px;
  background-color: ${COLOR_MAP.controls.clickableNormal};

  color: ${COLOR_MAP.controls.textAndIcon};
  text-transform: ${({ isUppercase }) => (isUppercase ? 'uppercase' : 'none')};

  user-select: none;

  & path {
    stroke: ${COLOR_MAP.controls.textAndIcon};
  }

  ${supportHover} {
    &:hover {
      background-color: ${COLOR_MAP.controls.clickableHover};
    }
  }

  &:active {
    background-color: ${COLOR_MAP.controls.clickablePressed};
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover {
    background-color: ${COLOR_MAP.controls.clickableDisabled};

    cursor: not-allowed;
  }
`;

export const ButtonV2 = ({
  size = BUTTON_SIZE.small,
  horizontalAlign = BUTTON_H_ALIGN.center,
  isUppercase = false,
  isStretch = false,
  isRegular = false,
  isNoPaddings = false,
  disabled = false,
  children,
  ...props
}: Partial<PropsButtonV2>) => {
  return (
    <ButtonComponent
      size={size}
      horizontalAlign={horizontalAlign}
      isUppercase={isUppercase}
      isStretch={isStretch}
      isRegular={isRegular}
      isNoPaddings={isNoPaddings}
      disabled={disabled}
      {...props}
    >
      {typeof children === 'string' ? children : children}
    </ButtonComponent>
  );
};
