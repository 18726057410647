export const PALLETE = {
  main: '#000000',
  second: '#CBAD73',
  background: '#ffffff',
  backgroundSecondary: '#F6F6F7',
  hr: '#E1E1E1',
  button: {
    basic: {
      background: '#CBAD73',
      text: '#ffffff',
    },
    secondary: {
      background: 'transparent',
      font: '#CBAD73',
      border: '#CBAD73',
      hoverFont: '#ffffff',
      hoverBackground: '#CBAD73',
    },
    disabledBackground: '#373737',
    disabledColor: '#ffffff',
  },
  checkbox: {
    disabled: 'rgb(120, 139, 165)',
  },
  input: {
    background: '#ffffff',
    border: '#C7CCD0',
  },
  status: {
    error: '#B51111',
  },
} as const;
