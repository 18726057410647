import React from 'react';
import styled from 'styled-components';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../componentUIOld/simple/Title';
import { COLOR_MAP } from '../componentUIOld/colorsMap';
import { truthfulAdaptive } from '../componentUIOld/screen';
import { Img } from './Img';
import { ErrorPropsType } from '../types/types';
import { Header } from '../UploadImg/components/Header';
import { useIsMobile } from '../hooks';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  min-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const DescriptionScreen = styled.div`
  display: flex;
  flex-direction: column;

  ${truthfulAdaptive.minWidth.desktop} {
    min-height: calc(100vh - 330px);
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
`;

const ImageContainer = styled.div`
  display: flex;
  margin: 0 0 40px 0;
  align-items: center;
  justify-content: center;
`;

export const Error = (
  props: ErrorPropsType = {
    errorTitle: 'Something went wrong.',
    errorMessage: 'Try again later or contact support.',
  },
) => {
  const { errorTitle, errorMessage } = props;
  const isMobile = useIsMobile();

  return (
    <MainContainer>
      <Header isTablet={isMobile} />
      <Container>
        <DescriptionScreen>
          <ContentWrapper>
            <ImageContainer>
              <Img />
            </ImageContainer>

            <Title
              size={TITLE_SIZE.h3}
              margins={[0, 0, 20, 0]}
              weight={TITLE_WEIGHT.normal}
              style={{ textAlign: 'center', maxWidth: '550px', wordBreak: 'break-word' }}
            >
              {errorTitle || 'Something went wrong.'}
            </Title>

            <div style={{ textAlign: 'center', maxWidth: '550px' }}>
              <Title
                size={TITLE_SIZE.h5}
                margins={[0, 0, 40, 0]}
                style={{ textAlign: 'center', maxWidth: '550px' }}
                colorText={COLOR_MAP.text.darkGrey}
                weight={TITLE_WEIGHT.normal}
              >
                {errorMessage || 'Try again later or contact support.'}
              </Title>
            </div>
          </ContentWrapper>
        </DescriptionScreen>
      </Container>
    </MainContainer>
  );
};
