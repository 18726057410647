export const TEXT_SIZE = {
  xl: 'xl',
  regular: 'regular',
  sub: 'sub',
  annotation: 'annotation',
} as const;

export const TEXT_WEIGHT = {
  bold: '700',
  normal: '400',
} as const;

export const SIZE_STYLE: Record<keyof typeof TEXT_SIZE, string> = {
  xl: 'font-size: 28px; line-height: 40px;', // deprecated
  regular: 'font-size: 16px; line-height: 22px;', // Text regular
  sub: 'font-size: 13px; line-height: 18px;', // Text sub
  annotation: 'font-size: 11px; line-height: 15px;', // Text annotation
};
