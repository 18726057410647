/* eslint-disable react/no-unstable-nested-components */
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { CSSProperties } from 'styled-components';
import { CloseIcon } from '../../common/icons/SvgInline/SvgIcons_2';
import { DarkButton } from '../../componentUI/simple/Button';
import {
  CustomModalContent,
  CustomModalNpsContent,
  CustomModalOverlay,
  CustomModalPhotoContent,
  stylesForCloseButton,
  stylesForCloseButtonMobile,
} from './styled';
import { useMatchMedia } from '../useMatchMedia';

/**
 * Props for the styled modal component useStyledModal.
 */
export interface ModalProps extends Omit<ReactModal.Props, 'isOpen'> {
  onClose: () => void;
  children: ReactNode;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  stylesForModal?: CSSProperties;
  isBigHeight?: boolean;
  isNps?: boolean;
  isPhoto?: boolean;
}

ReactModal.setAppElement('#root'); // required for accessibility

/**
 * Returns a styled modal and a function to toggle its visibility.
 * Accepts all parameters for ReactModal and allows changing width and height with props.
 *
 * @returns {[Component, Function]} A tuple containing the modal component and a function to externally toggle its visibility.
 *
 * External usage example:
 * ```jsx
 * const [Modal, toggleModal] = useStyledModal();
 * toggleModal(); // Open the modal in the parent component
 *
 * const onCloseModal = () => {
 *   console.log('Modal closed');
 * };
 *
 * // ...
 * return (
 *  <Modal onClose={onCloseModal}><h1>StyledModal</h1></Modal>
 * )
 * ```
 */

export const useStyledModal = (): [(props: ModalProps) => JSX.Element, () => void] => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNps, setIsNps] = useState(false); // Define isNps state
  const { isMobile } = useMatchMedia();

  // need to disable scrolling bug, if u now how to fix this - do this
  useEffect(() => {
    if (isOpen && !isNps) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen, isNps]);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const Modal = ({
    onClose,
    children,
    width,
    height,
    stylesForModal,
    isBigHeight = false,
    isNps: modalIsNps = false,
    isPhoto = false,
    ...props
  }: ModalProps) => {
    setIsNps(modalIsNps);

    return (
      <ReactModal
        onRequestClose={() => {
          onClose();
          toggleModal();
        }}
        shouldFocusAfterRender
        className="_"
        overlayClassName="_"
        overlayElement={
          !modalIsNps
            ? (props, contentElement) => <CustomModalOverlay {...props}>{contentElement}</CustomModalOverlay>
            : undefined
        }
        contentElement={(props, children) => (
          <>
            {isPhoto ? (
              <CustomModalPhotoContent
                {...props}
                style={{
                  ...props.style,
                  width: width ?? props.style?.width,
                  height: height ?? props.style?.height,
                  ...stylesForModal,
                }}
                isBigHeight={isBigHeight}
              >
                {children}
              </CustomModalPhotoContent>
            ) : modalIsNps ? (
              <CustomModalNpsContent
                {...props}
                style={{
                  ...props.style,
                  width: width ?? props.style?.width,
                  height: height ?? props.style?.height,
                  ...stylesForModal,
                }}
                isBigHeight={isBigHeight}
              >
                {children}
              </CustomModalNpsContent>
            ) : (
              <CustomModalContent
                {...props}
                style={{
                  ...props.style,
                  width: width ?? props.style?.width,
                  height: height ?? props.style?.height,
                  ...stylesForModal,
                }}
                isBigHeight={isBigHeight}
              >
                {children}
              </CustomModalContent>
            )}
          </>
        )}
        {...props}
        isOpen={isOpen}
      >
        <DarkButton
          onClick={() => {
            onClose();
            toggleModal();
          }}
          style={isMobile ? stylesForCloseButtonMobile : stylesForCloseButton}
        >
          <CloseIcon size={isMobile ? '28px' : '40px'} />
        </DarkButton>
        {children}
      </ReactModal>
    );
  };

  return [Modal, toggleModal];
};
