/* eslint-disable max-len */
import React from 'react';

import styled from 'styled-components';

const CrossBg = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.19727 14.457C1.58398 14.835 2.2168 14.835 2.58594 14.4658L7.99121 9.06055L13.3965 14.4658C13.7744 14.835 14.4072 14.8438 14.7852 14.457C15.1631 14.0791 15.1631 13.4463 14.7939 13.0771L9.38867 7.66309L14.7939 2.25781C15.1631 1.88867 15.1719 1.24707 14.7852 0.87793C14.3984 0.5 13.7744 0.5 13.3965 0.869141L7.99121 6.27441L2.58594 0.869141C2.2168 0.5 1.5752 0.491211 1.19727 0.869141C0.819336 1.25586 0.828125 1.89746 1.19727 2.25781L6.60254 7.66309L1.19727 13.0771C0.828125 13.4375 0.819336 14.0879 1.19727 14.457Z"
      fill="#1C1C1E"
    />
  </svg>
);

const CrossButtonComponent = styled.button`
  border: none;
  user-select: none;
  background: none;
  font-weight: 700;
  font-size: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
// TODO сделать управляемым размер крестика
export const CrossButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <CrossButtonComponent onClick={onClick}>
      <CrossBg />
    </CrossButtonComponent>
  );
};
