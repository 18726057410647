import React, { Dispatch, SetStateAction, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '../../common/icons/SvgInline/SvgIcons_4';
import { DarkButton } from '../../componentUI/simple/Button';
import { TEXT_SIZE, TEXT_WEIGHT, Text } from '../../componentUI/simple/Text';

import { textFirstColumn, textSecondColumn } from './data';
import { InstructionsContainer, InstructionsWrapper, TitleWrapper, UnorderedList } from './styled';

type Props = {
  setShowInstructions?: Dispatch<SetStateAction<boolean>>;
  textTitle: string;
  isSelect: boolean;
  firstColumnText?: Array<string>;
  secondColumnText?: Array<string>;
};

export const PhotoInstructions = ({
  setShowInstructions,
  textTitle,
  firstColumnText,
  secondColumnText,
  isSelect,
}: Props) => {
  const [isShow, setIsShow] = useState(false);
  const firstColText = firstColumnText || textFirstColumn;
  const secondColText = secondColumnText || textSecondColumn;

  const toggleShow = () => {
    setIsShow((prev) => !prev);
  };

  const toggleShowInstructions = () => {
    if (setShowInstructions) {
      setShowInstructions(false);
    }
  };

  return isSelect ? (
    <InstructionsContainer>
      <TitleWrapper isSelect={isSelect} onClick={toggleShow}>
        <Text size={TEXT_SIZE.regular} weight={TEXT_WEIGHT.bold}>
          {textTitle}
        </Text>
        <DarkButton>{isShow ? <ChevronUpIcon /> : <ChevronDownIcon />}</DarkButton>
      </TitleWrapper>
      {isShow && (
        <InstructionsWrapper>
          <UnorderedList>
            {firstColText.map((value) => (
              <li key={value}>
                <Text size={TEXT_SIZE.sub}>{value}</Text>
              </li>
            ))}
          </UnorderedList>
          <UnorderedList>
            {secondColText.map((value) => (
              <li key={value}>
                <Text size={TEXT_SIZE.sub}>{value}</Text>
              </li>
            ))}
          </UnorderedList>
        </InstructionsWrapper>
      )}
    </InstructionsContainer>
  ) : (
    <InstructionsContainer>
      <TitleWrapper isSelect={isSelect}>
        <Text size={TEXT_SIZE.regular} weight={TEXT_WEIGHT.bold}>
          {textTitle}
        </Text>
        <DarkButton onClick={toggleShowInstructions}>
          <ChevronUpIcon />
        </DarkButton>
      </TitleWrapper>
      <InstructionsWrapper>
        <UnorderedList>
          {firstColText.map((value) => (
            <li key={value}>
              <Text size={TEXT_SIZE.sub}>{value}</Text>
            </li>
          ))}
        </UnorderedList>
        <UnorderedList>
          {secondColText.map((value) => (
            <li key={value}>
              <Text size={TEXT_SIZE.sub}>{value}</Text>
            </li>
          ))}
        </UnorderedList>
      </InstructionsWrapper>
    </InstructionsContainer>
  );
};
