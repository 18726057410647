import React, { ChangeEvent, InputHTMLAttributes, isValidElement, ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';
import { PALLETE } from '../../pallete';
import { noop, VisuallyHiddenInput } from '../../utils';
import { Text } from '../Text';
import { CHECKBOX_BODY_STYLE, checkboxTypes, TYPE_CHECKBOX } from './constants';
import { IconCheckbox } from './IconCheckbox';

type BasePropsCheckbox = {
  checked?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  typeCheckbox: checkboxTypes;
};

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
  Omit<BasePropsCheckbox, 'typeCheckbox'> & {
    onChange?: (isChecked: boolean) => void;
    isRight?: boolean;
    label?: string | ReactNode;
    typeCheckbox?: checkboxTypes;
  };

const CheckboxWrapper = styled.label<{ isRight?: boolean; disabled?: boolean }>`
  box-sizing: border-box;
  display: flex;

  gap: 8px;
  ${({ isRight }) => (isRight ? 'flex-direction: row-reverse; justify-content: flex-end;' : '')};
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')};

  user-select: none;
  cursor: pointer;
`;

const CheckboxRender = styled.span<BasePropsCheckbox>`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ typeCheckbox }) => CHECKBOX_BODY_STYLE[typeCheckbox]}

  border-color: ${({ hasError, disabled, typeCheckbox }) =>
    (typeCheckbox === 'type2' && COLOR_MAP.controls.disabled) || // костыль
    (hasError && PALLETE.status.error) ||
    (disabled && COLOR_MAP.controls.clickableDisabled)};
  border-radius: 3px;
`;

export const Checkbox = ({
  checked = false,
  disabled,
  label = '',
  hasError,
  onChange = noop,
  typeCheckbox = TYPE_CHECKBOX.type1,
  isRight,
}: Props) => {
  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked);
    },
    [onChange],
  );

  const labelRender = isValidElement(label) ? label : <Text>{label}</Text>;

  return (
    <CheckboxWrapper isRight={isRight} disabled={disabled}>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <VisuallyHiddenInput type="checkbox" onChange={changeHandler} checked={checked} />
        <CheckboxRender disabled={disabled} hasError={hasError} typeCheckbox={typeCheckbox}>
          {checked && <IconCheckbox typeCheckbox={typeCheckbox} />}
        </CheckboxRender>
      </div>
      {labelRender}
    </CheckboxWrapper>
  );
};
