import React from 'react';
import styled from 'styled-components';
import { Text, TEXT_SIZE } from '../../componentUI/simple/Text';
import { COLOR_MAP } from '../../componentUI/colorsMap';

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
`;

const CommentWrapper = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 8px;
  background: ${COLOR_MAP.background.lightGrey};
`;

export const CardComment = ({ comment }: { comment: string | undefined }) => {
  if (!comment) return null;

  return (
    <CommentContainer>
      <Text size={TEXT_SIZE.sub}>Authenticator&apos;s comment</Text>
      <CommentWrapper>
        <Text>{comment}</Text>
      </CommentWrapper>
    </CommentContainer>
  );
};
