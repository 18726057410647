const textFirstColumn = [
  'File format: JPEG, PNG, HEIC;',
  'File size: less or equal 5MB;',
  'Resolution: should be high enough to maintain clarity and detail;',
];
const textSecondColumn = [
  'Orientation: make sure the photo is oriented correctly;',
  'Quality: The photo should be clear, well-lit, and free from any blurring or distortion.',
];

export { textFirstColumn, textSecondColumn };
