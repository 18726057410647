import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import { AppTheme } from './commonOld';
import { AppStyled } from './AppStyled';
import { UploadImg } from './UploadImg/UploadImg';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <AppTheme>
    <React.StrictMode>
      <BrowserRouter>
        <AppStyled />
        <UploadImg />
      </BrowserRouter>
    </React.StrictMode>
  </AppTheme>,
);
