import React from 'react';
import styled from 'styled-components';
import { Picture } from '../../componentUIOld/complex/Picture';
import { LogoMobile } from '../../commonOld';

interface HeaderProps {
  isTablet: boolean;
}

const HeaderWrapper = styled.div`
  display: flex;
  padding: 40px 0 20px 0;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const Header = ({ isTablet }: HeaderProps) => {
  return (
    <HeaderWrapper>
      <Picture url={LogoMobile} width={isTablet ? 50 : 100} height={isTablet ? 37 : 73} />
    </HeaderWrapper>
  );
};
