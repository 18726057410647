/* eslint-disable max-len */
import React from 'react';

const CreditIcon = ({ size = '46', color = '#FFFFFF' }: { size?: string; color?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="credit icon">
        <path
          id="Polygon 1"
          d="M23 1L38.5563 7.44365M23 1L7.44365 7.44365M23 1L17.1066 8.77227M23 1L28.8932 8.77227M38.5563 7.44365L45 23M38.5563 7.44365L28.8932 8.77227M38.5563 7.44365L37.2277 17.1067M45 23L38.5563 38.5563M45 23L37.2277 17.1067M45 23L37.2277 28.8933M38.5563 38.5563L23 45M38.5563 38.5563L37.2277 28.8933M38.5563 38.5563L28.8932 37.2278M23 45L7.44365 38.5563M23 45L28.8932 37.2278M23 45L17.1066 37.2278M7.44365 38.5563L1 23M7.44365 38.5563L17.1066 37.2278M7.44365 38.5563L8.77217 28.8933M1 23L7.44365 7.44365M1 23L8.77217 28.8933M1 23L8.77217 17.1067M7.44365 7.44365L8.77217 17.1067M7.44365 7.44365L17.1066 8.77227M28.8932 8.77227L37.2277 17.1067M28.8932 8.77227H17.1066M37.2277 17.1067V28.8933M37.2277 28.8933L28.8932 37.2278M28.8932 37.2278H17.1066M17.1066 37.2278L8.77217 28.8933M8.77217 28.8933V17.1067M8.77217 17.1067L17.1066 8.77227"
          stroke={color}
          strokeWidth="1.4"
        />
        <path
          id="Polygon 4"
          d="M22.9072 11.7679L28.4768 13.2603M22.9072 11.7679L17.3376 13.2603M22.9072 11.7679V1M22.9072 11.7679V22.9072M28.4768 13.2603L32.554 17.3376M28.4768 13.2603L38.5021 7.31224L32.554 17.3376M28.4768 13.2603L22.9072 1M28.4768 13.2603L28.5 17.5M32.554 17.3376L34.0464 22.9072M32.554 17.3376L45 22.9072M32.554 17.3376L28.5 17.5M34.0464 22.9072L32.554 28.4768M34.0464 22.9072H45M34.0464 22.9072H22.9072M32.554 28.4768L28.4768 32.554M32.554 28.4768L38.5021 38.5021L28.4768 32.554M32.554 28.4768L45 22.9072M32.554 28.4768L28.5 28.5M28.4768 32.554L22.9072 34.0464M28.4768 32.554L22.9072 45M28.4768 32.554L28.5 28.5M22.9072 34.0464L17.3376 32.554M22.9072 34.0464V45M22.9072 34.0464V22.9072M17.3376 32.554L13.2603 28.4768M17.3376 32.554L22.9072 45M17.3376 32.554L7.31224 38.6878L13.2603 28.4768M17.3376 32.554L17.5 28.5M13.2603 28.4768L11.7679 22.9072M13.2603 28.4768L1 22.9072M13.2603 28.4768L17.5 28.5M11.7679 22.9072L13.2603 17.3376M11.7679 22.9072H1M11.7679 22.9072H22.9072M13.2603 17.3376L17.3376 13.2603M13.2603 17.3376L1 22.9072M13.2603 17.3376L7.31224 7.31224L17.3376 13.2603M13.2603 17.3376L17.5 17.5M17.3376 13.2603L22.9072 1M17.3376 13.2603L17.5 17.5M22.9072 22.9072L28.5 17.5M22.9072 22.9072L28.5 28.5M22.9072 22.9072L17.5 28.5M22.9072 22.9072L17.5 17.5"
          stroke="url(#paint0_diamond_14713_89508)"
          strokeWidth="0.8"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_diamond_14713_89508"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23 23) rotate(90) scale(22)"
        >
          <stop stopColor={color} stopOpacity="0.67" />
          <stop offset="1" stopColor={color} stopOpacity="0.22" />
        </radialGradient>
      </defs>
    </svg>
  );
};

const CreditIconV2 = ({ size = '44', color = '#CBAD73' }: { size?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44" fill="none">
      <path
        d="M22 2L36.1421 7.85786M22 2L7.85786 7.85786M22 2L16.6424 9.0657M22 2L27.3575 9.0657M36.1421 7.85786L42 22M36.1421 7.85786L27.3575 9.0657M36.1421 7.85786L34.9342 16.6424M42 22L36.1421 36.1421M42 22L34.9342 16.6424M42 22L34.9342 27.3576M36.1421 36.1421L22 42M36.1421 36.1421L34.9342 27.3576M36.1421 36.1421L27.3575 34.9343M22 42L7.85786 36.1421M22 42L27.3575 34.9343M22 42L16.6424 34.9343M7.85786 36.1421L2 22M7.85786 36.1421L16.6424 34.9343M7.85786 36.1421L9.06561 27.3576M2 22L7.85786 7.85786M2 22L9.06561 27.3576M2 22L9.06561 16.6424M7.85786 7.85786L9.06561 16.6424M7.85786 7.85786L16.6424 9.0657M27.3575 9.0657L34.9342 16.6424M27.3575 9.0657H16.6424M34.9342 16.6424V27.3576M34.9342 27.3576L27.3575 34.9343M27.3575 34.9343H16.6424M16.6424 34.9343L9.06561 27.3576M9.06561 27.3576V16.6424M9.06561 16.6424L16.6424 9.0657"
        stroke={color}
        strokeWidth="1.6"
      />
      <path
        d="M21.9156 11.789L26.9789 13.1457M21.9156 11.789L16.8523 13.1457M21.9156 11.789V2M21.9156 11.789V21.9156M26.9789 13.1457L30.6855 16.8523M26.9789 13.1457L36.0928 7.7384L30.6855 16.8523M26.9789 13.1457L21.9156 2M26.9789 13.1457L27 17M30.6855 16.8523L32.0422 21.9156M30.6855 16.8523L42 21.9156M30.6855 16.8523L27 17M32.0422 21.9156L30.6855 26.9789M32.0422 21.9156H42M32.0422 21.9156H21.9156M30.6855 26.9789L26.9789 30.6855M30.6855 26.9789L36.0928 36.0928L26.9789 30.6855M30.6855 26.9789L42 21.9156M30.6855 26.9789L27 27M26.9789 30.6855L21.9156 32.0422M26.9789 30.6855L21.9156 42M26.9789 30.6855L27 27M21.9156 32.0422L16.8523 30.6855M21.9156 32.0422V42M21.9156 32.0422V21.9156M16.8523 30.6855L13.1457 26.9789M16.8523 30.6855L21.9156 42M16.8523 30.6855L7.7384 36.2616L13.1457 26.9789M16.8523 30.6855L17 27M13.1457 26.9789L11.789 21.9156M13.1457 26.9789L2 21.9156M13.1457 26.9789L17 27M11.789 21.9156L13.1457 16.8523M11.789 21.9156H2M11.789 21.9156H21.9156M13.1457 16.8523L16.8523 13.1457M13.1457 16.8523L2 21.9156M13.1457 16.8523L7.7384 7.7384L16.8523 13.1457M13.1457 16.8523L17 17M16.8523 13.1457L21.9156 2M16.8523 13.1457L17 17M21.9156 21.9156L27 17M21.9156 21.9156L27 27M21.9156 21.9156L17 27M21.9156 21.9156L17 17"
        stroke="url(#paint0_diamond_14710_47411)"
      />
      <defs>
        <radialGradient
          id="paint0_diamond_14710_47411"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22 22) rotate(90) scale(20)"
        >
          <stop stopColor={color} stopOpacity="0.4" />
          <stop offset="0.911663" stopColor={color} stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

const ArrowDownCircleIcon = ({ size = '20', color = '#DE4141' }: { size?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_15275_2530)">
        <path
          d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 9.99999C18.3334 5.39762 14.6025 1.66666 10.0001 1.66666C5.39771 1.66666 1.66675 5.39762 1.66675 9.99999C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66675 10L10.0001 13.3333L13.3334 10"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M10 6.66666V13.3333" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_15275_2530">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ArrowUpCircleIcon = ({ size = '20', color = '#6BCBA9' }: { size?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_15275_2584)">
        <path
          d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 9.99999C18.3334 5.39762 14.6025 1.66666 10.0001 1.66666C5.39771 1.66666 1.66675 5.39762 1.66675 9.99999C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3334 9.99999L10.0001 6.66666L6.66675 9.99999"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M10 13.3333V6.66666" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_15275_2584">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const LinkIcon = ({ size = '16', color = '#CBAD73' }: { size?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_15275_1077)">
        <path
          d="M6.66675 8.66666C6.95305 9.04942 7.31832 9.36612 7.73778 9.59529C8.15724 9.82446 8.62109 9.96074 9.09785 9.99489C9.57461 10.029 10.0531 9.96024 10.501 9.79318C10.9488 9.62613 11.3555 9.36471 11.6934 9.02666L13.6934 7.02666C14.3006 6.39799 14.6366 5.55598 14.629 4.68199C14.6214 3.808 14.2708 2.97196 13.6528 2.35394C13.0348 1.73591 12.1987 1.38535 11.3248 1.37775C10.4508 1.37016 9.60876 1.70614 8.98008 2.31333L7.83341 3.45333"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33322 7.33332C9.04692 6.95057 8.68165 6.63387 8.26219 6.40469C7.84273 6.17552 7.37888 6.03924 6.90212 6.0051C6.42536 5.97095 5.94683 6.03974 5.49899 6.2068C5.05115 6.37386 4.64448 6.63527 4.30656 6.97332L2.30656 8.97332C1.69936 9.60199 1.36338 10.444 1.37098 11.318C1.37857 12.192 1.72913 13.028 2.34716 13.646C2.96519 14.2641 3.80123 14.6146 4.67522 14.6222C5.54921 14.6298 6.39121 14.2938 7.01989 13.6867L8.15989 12.5467"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15275_1077">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const PresentIcon = ({ size = '40' }: { size?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 40 40" fill="none">
      <path d="M33.3334 20V36.6667H6.66669V20" stroke="#CBAD73" strokeWidth="1.6" />
      <path d="M36.6666 11.6667H3.33331V20H36.6666V11.6667Z" stroke="#CBAD73" strokeWidth="1.6" />
      <path d="M20 36.6667V11.6667" stroke="#CBAD73" strokeWidth="1.6" />
      <path
        d="M12.5 11.6667H20C20 11.6667 18.3333 3.33334 12.5 3.33334C11.3949 3.33334 10.3351 3.77233 9.5537 4.55373C8.7723 5.33513 8.33331 6.39494 8.33331 7.50001C8.33331 8.60508 8.7723 9.66489 9.5537 10.4463C10.3351 11.2277 11.3949 11.6667 12.5 11.6667Z"
        stroke="#CBAD73"
        strokeWidth="1.6"
      />
      <path
        d="M27.5 11.6667H20C20 11.6667 21.6667 3.33334 27.5 3.33334C28.6051 3.33334 29.6649 3.77233 30.4463 4.55373C31.2277 5.33513 31.6667 6.39494 31.6667 7.50001C31.6667 8.60508 31.2277 9.66489 30.4463 10.4463C29.6649 11.2277 28.6051 11.6667 27.5 11.6667Z"
        stroke="#CBAD73"
        strokeWidth="1.6"
      />
      <path d="M7 15.5H33M13 34V22M26.5 34V22" stroke="url(#paint0_diamond_15720_21786)" />
      <defs>
        <radialGradient
          id="paint0_diamond_15720_21786"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 24.75) rotate(120.205) scale(10.7032 13.1626)"
        >
          <stop offset="0.199101" stopColor="#CBAD73" stopOpacity="0" />
          <stop offset="1" stopColor="#CBAD73" stopOpacity="0.4" />
        </radialGradient>
      </defs>
    </svg>
  );
};

const CrossInCircleIcon = ({ size = '20', color = '#AAAAAA' }: { size?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_14638_6558)">
        <path
          d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 9.99999C18.3334 5.39762 14.6025 1.66666 10.0001 1.66666C5.39771 1.66666 1.66675 5.39762 1.66675 9.99999C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12.5 7.5L7.5 12.5" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 7.5L12.5 12.5" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_14638_6558">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const UsersIcon = ({ size = '16', color = '#CBAD73' }: { size?: string; color?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_17683_39694)">
        <path
          d="M11.3332 14V12.6667C11.3332 11.9594 11.0522 11.2811 10.5521 10.781C10.052 10.281 9.37375 10 8.6665 10H3.33317C2.62593 10 1.94765 10.281 1.44755 10.781C0.947456 11.2811 0.666504 11.9594 0.666504 12.6667V14"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.00016 7.33333C7.47292 7.33333 8.66683 6.13943 8.66683 4.66667C8.66683 3.19391 7.47292 2 6.00016 2C4.5274 2 3.3335 3.19391 3.3335 4.66667C3.3335 6.13943 4.5274 7.33333 6.00016 7.33333Z"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.3335 14.0002V12.6669C15.3331 12.0761 15.1364 11.5021 14.7744 11.0351C14.4124 10.5682 13.9056 10.2346 13.3335 10.0869"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6665 2.08691C11.2401 2.23378 11.7485 2.56738 12.1116 3.03512C12.4747 3.50286 12.6717 4.07813 12.6717 4.67025C12.6717 5.26236 12.4747 5.83763 12.1116 6.30537C11.7485 6.77311 11.2401 7.10671 10.6665 7.25358"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17683_39694">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const LeftNavIcon = ({ color = '#CBAD73' }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="38" viewBox="0 0 20 38" fill="none">
      <g clipPath="url(#clip0_18112_38933)">
        <path
          d="M18.4242 35.7699L1.75757 19.0052L18.4242 2.24048"
          stroke={color}
          strokeWidth="3.28846"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18112_38933">
          <rect width="20" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const RightNavIcon = ({ color = '#CBAD73' }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="38" viewBox="0 0 20 38" fill="none">
      <g clipPath="url(#clip0_18112_24847)">
        <path
          d="M1.57577 2.23014L18.2424 18.9948L1.57577 35.7595"
          stroke={color}
          strokeWidth="3.28846"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18112_24847">
          <rect width="20" height="38" fill="white" transform="matrix(-1 0 0 -1 20 38)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  CreditIcon,
  CreditIconV2,
  CrossInCircleIcon,
  LinkIcon,
  PresentIcon,
  UsersIcon,
  LeftNavIcon,
  RightNavIcon,
};
