import axios from 'axios';

// @ts-ignore
export const instance = axios.create({
  baseURL: 'https://upload.uploadcare.com/',
});

type BodyType = {
  UPLOADCARE_PUB_KEY: string;
  UPLOADCARE_STORE: string;
  file: File;
  file_name: string;
};

export const uploadPhoto = async <T>(body: BodyType): Promise<T> => {
  const result = await instance.post('base/?jsonerrors=1', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return result.data;
};

export const getInfoPhoto = async <T>(pub_key: string, file_id: string): Promise<T> => {
  const result = await instance.get(`info/?jsonerrors=1&pub_key=${pub_key}&file_id=${file_id}`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return result.data;
};

export const fetchToken = () =>
  fetch('https://dev-i88utbyp.us.auth0.com/oauth/token', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      Cookie:
        'did=s%3Av0%3A68ad6510-2fba-11ed-a43f-0d825b60e589.azCkDIxPNauijrjjfxqgAPZp7nrixJyeUo3MFFUVQpA; did_compat=s%3Av0%3A68ad6510-2fba-11ed-a43f-0d825b60e589.azCkDIxPNauijrjjfxqgAPZp7nrixJyeUo3MFFUVQpA',
    },
    body: JSON.stringify({
      client_id: 'Md191uCRfcv1CHleKYcCa1bXZr4qxulR',
      client_secret: '9B5Ne07xTYn5JW-k6OHp1yl9YIU1T_u-wjCuB3HZr-h0pVRMIfzJe7npj0EjM0rX',
      audience: 'https://auth0-jwt-authorizer',
      grant_type: 'client_credentials',
    }),
  });

export const fetchGetPhotoList = async <T>(token: string, testId: string, admin?: string): Promise<T> => {
  const url = `https://6fmw7dpqu4.execute-api.eu-west-2.amazonaws.com/1/photos-to-submit?id=${testId}${
    admin ? `&admin=${admin}` : ''
  }`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const body = await response.json();

  return body;
};

export const postPhotos = async <T>(
  token: string,
  bodyRequest: { id: string; photos: Array<{ name: string; value: string }> },
  admin?: string,
): Promise<T> => {
  const newBody = admin ? { ...bodyRequest, admin } : bodyRequest;

  console.log(newBody);

  const response = await fetch('https://6fmw7dpqu4.execute-api.eu-west-2.amazonaws.com/1/photos-bulk-reupload', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(newBody),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const body = await response.json();

  return body;
};

export const postPhotosV2 = async <T>(
  token: string,
  bodyRequest: { id: string; client_id: string; photos: Array<{ index: string; url: string }> },
  admin?: string,
): Promise<T> => {
  const newBody = admin ? { ...bodyRequest, admin } : bodyRequest;

  const response = await fetch(
    'https://6fmw7dpqu4.execute-api.eu-west-2.amazonaws.com/1/external-photos-bulk-reupload',
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newBody),
    },
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const body = await response.json();

  return body;
};

export const rejectOrder = async <T>(token: string, uniqueCode: string): Promise<T> => {
  const response = await fetch('https://6fmw7dpqu4.execute-api.eu-west-2.amazonaws.com/1/updateTask', {
    method: 'PATCH',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      id: uniqueCode,
      status: 'closed',
      outcome: 'canceled',
      outcome_reason: 'FPR',
      user: 'borjk',
    }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const body = await response.json();

  return body;
};
