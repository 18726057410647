import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { checkboxTypes } from './constants';
import { COLOR_MAP } from '../../colorsMap';

const WRAPPER_STYLE: Record<checkboxTypes, FlattenSimpleInterpolation> = {
  type1: css`
    rect {
      fill: ${COLOR_MAP.controls.clickableNormal};
    }

    &:hover {
      rect {
        fill: ${COLOR_MAP.controls.clickableHover};
      }
    }
  `,
  type2: css`
    rect {
      fill: ${COLOR_MAP.controls.disabled};
    }

    &:hover {
      rect {
        fill: ${COLOR_MAP.controls.disabled};
      }
    }
  `,
};

const SvgStyled = styled.svg<{ typeCheckbox: checkboxTypes }>`
  ${({ typeCheckbox }) => WRAPPER_STYLE[typeCheckbox]}
`;

export const IconCheckbox = ({ typeCheckbox }: { typeCheckbox: checkboxTypes }) => {
  return (
    <SvgStyled
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      typeCheckbox={typeCheckbox}
    >
      <rect width="20" height="20" rx="2" fill="#CBAD73" />
      <path
        d="M14.6668 6.5L8.25016 12.9167L5.3335 10"
        stroke="white"
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgStyled>
  );
};
