import React from 'react';
import styled from 'styled-components';
import { ResponseGetPhotoList, Status } from './types';
import { Text } from '../componentUIOld/simple/Text';
import { Loader } from '../componentUIOld/Loader';

type Props = {
  statusRequest: Status;
  requiredSubmit: Array<ResponseGetPhotoList>;
  optionalSubmit: Array<ResponseGetPhotoList>;
};

const LoadingWrapper = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoImagesWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const LoadingStatus = ({ statusRequest, requiredSubmit, optionalSubmit }: Props) => {
  return (
    <>
      {statusRequest === Status.Loading && (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      )}
      {statusRequest === Status.Error && (
        <ErrorWrapper>
          <Text style={{ textAlign: 'center' }}>There was an error loading, please try again</Text>
        </ErrorWrapper>
      )}
      {statusRequest === Status.NoId && (
        <ErrorWrapper>
          <Text style={{ textAlign: 'center' }}>
            Your task ID is not found or incorrect, please check your link or contact support for assistance
          </Text>
        </ErrorWrapper>
      )}
      {statusRequest !== Status.Error &&
        statusRequest !== Status.Loading &&
        !requiredSubmit.length &&
        !optionalSubmit.length && (
          <NoImagesWrapper>
            <Text>No images to download</Text>
          </NoImagesWrapper>
        )}
    </>
  );
};
