export const TEXT_SIZE = {
  xl: 'xl',
  l: 'l',
  m: 'm',
  sm: 'sm',
  s: 's',
} as const;

export const TEXT_WEIGHT = {
  bold: '700',
  normal: '400',
} as const;

export const SIZE_STYLE: Record<keyof typeof TEXT_SIZE, string> = {
  xl: 'font-size: 28px; line-height: 40px;',
  l: 'font-size: 23px; line-height: 32px;',
  m: 'font-size: 18px; line-height: 25px;',
  sm: 'font-size: 16px; line-height: normal;',
  s: 'font-size: 14px; line-height: 20px;',
};
