import React from 'react';

import styled from 'styled-components';
import { PropsButton } from './types';
import { buttonTextStyle, commonStyles } from './utils';
import { PALLETE } from '../../pallete';
import { BUTTON_SIZE, PADDING_SECONDARY, SIZE_STYLE } from './constants';
import { supportHover } from '../../screen';

const ButtonComponent = styled.button<PropsButton>`
  ${commonStyles};
  ${buttonTextStyle};
  border: 1px solid #b51111;
  user-select: none;
  background-color: ${PALLETE.button.secondary.background};
  color: #b51111;

  padding: ${({ size }) => PADDING_SECONDARY[size]};

  ${({ size }) => SIZE_STYLE[size]};

  ${supportHover} {
    &:hover {
      color: ${PALLETE.button.secondary.hoverFont};
      background-color: #b51111;
    }
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover {
    background-color: #b51111;
    border-color: ${PALLETE.button.disabledBackground};
    color: ${PALLETE.button.disabledColor};

    cursor: not-allowed;
  }
`;

export const SecondDangerButton = ({
  size = BUTTON_SIZE.small,
  isStretch = false,
  disabled = false,
  ...props
}: Partial<PropsButton>) => {
  return <ButtonComponent size={size} isStretch={isStretch} disabled={disabled} {...props} />;
};
