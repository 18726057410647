import React from 'react';

export const Img = () => {
  return (
    <svg width="229" height="203" viewBox="0 0 229 203" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_390_1785)">
        <circle cx="170.454" cy="37.4542" r="5.07116" stroke="#EFD6A6" strokeWidth="2.76609" />
      </g>
      <circle cx="142" cy="67" r="40" fill="#C1A56C" />
      <g filter="url(#filter1_d_390_1785)">
        <circle cx="142" cy="67" r="32" fill="#D7BA83" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.076 54.5532C158.155 55.4071 158.312 56.943 157.427 57.9837L139.462 79.1086C139.021 79.6268 138.379 79.947 137.686 79.994C136.993 80.041 136.311 79.8107 135.799 79.3572L126.817 71.392C125.79 70.4811 125.722 68.9391 126.666 67.948C127.61 66.9568 129.209 66.8918 130.236 67.8027L137.251 74.0232L153.521 54.8914C154.406 53.8507 155.997 53.6993 157.076 54.5532Z"
        fill="#705930"
      />
      <circle cx="165" cy="128" r="40" fill="#C1A56C" />
      <g filter="url(#filter2_d_390_1785)">
        <circle cx="165" cy="128" r="32" fill="#D7BA83" />
      </g>
      <path
        d="M153 116L165 128M177 140L165 128M165 128L177 116M165 128L153 140"
        stroke="#705930"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="104" cy="113" r="40" fill="#C1A56C" />
      <g filter="url(#filter3_d_390_1785)">
        <circle cx="104" cy="113" r="32" fill="#D7BA83" />
      </g>
      <path
        d="M121 100.636V109.909H111.727"
        stroke="#705930"
        strokeWidth="4.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87 125.364V116.091H96.2727"
        stroke="#705930"
        strokeWidth="4.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.8791 108.364C91.6629 106.149 92.995 104.168 94.7512 102.607C96.5073 101.046 98.6303 99.9559 100.922 99.4374C103.214 98.9189 105.599 98.9894 107.856 99.6422C110.113 100.295 112.168 101.509 113.829 103.171L121 109.909M87 116.091L94.1709 122.829C95.8319 124.491 97.8868 125.705 100.144 126.358C102.401 127.01 104.786 127.081 107.078 126.562C109.37 126.044 111.493 124.953 113.249 123.392C115.005 121.832 116.337 119.851 117.121 117.636"
        stroke="#705930"
        strokeWidth="4.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.2983 26C58.7149 26 59.0527 26.319 59.0527 26.7125C59.0527 30.2539 62.0924 33.1248 65.8421 33.1248C66.2588 33.1248 66.5965 33.4438 66.5965 33.8373C66.5965 34.2307 66.2588 34.5497 65.8421 34.5497C62.0924 34.5497 59.0527 37.4206 59.0527 40.962C59.0527 41.3555 58.7149 41.6745 58.2983 41.6745C57.8816 41.6745 57.5439 41.3555 57.5439 40.962C57.5439 37.4206 54.5041 34.5497 50.7544 34.5497C50.3378 34.5497 50 34.2307 50 33.8373C50 33.4438 50.3378 33.1248 50.7544 33.1248C54.5041 33.1248 57.5439 30.2539 57.5439 26.7125C57.5439 26.319 57.8816 26 58.2983 26ZM58.2983 29.982C57.4705 31.6827 56.017 33.0555 54.2162 33.8373C56.017 34.619 57.4705 35.9918 58.2983 37.6925C59.126 35.9918 60.5795 34.619 62.3803 33.8373C60.5795 33.0555 59.126 31.6827 58.2983 29.982Z"
        fill="#BCA371"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 48C46.6527 48 47.1818 48.5088 47.1818 49.1364C47.1818 54.7847 51.9439 59.3636 57.8182 59.3636C58.4709 59.3636 59 59.8724 59 60.5C59 61.1276 58.4709 61.6364 57.8182 61.6364C51.9439 61.6364 47.1818 66.2153 47.1818 71.8636C47.1818 72.4912 46.6527 73 46 73C45.3473 73 44.8182 72.4912 44.8182 71.8636C44.8182 66.2153 40.0561 61.6364 34.1818 61.6364C33.5291 61.6364 33 61.1276 33 60.5C33 59.8724 33.5291 59.3636 34.1818 59.3636C40.0561 59.3636 44.8182 54.7847 44.8182 49.1364C44.8182 48.5088 45.3473 48 46 48ZM46 54.3511C44.7033 57.0637 42.4262 59.2532 39.6051 60.5C42.4262 61.7468 44.7033 63.9363 46 66.6489C47.2967 63.9363 49.5738 61.7468 52.3949 60.5C49.5738 59.2532 47.2967 57.0637 46 54.3511Z"
        fill="#BCA371"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.9932 174C93.2941 174 93.538 174.225 93.538 174.503C93.538 177.003 95.7334 179.029 98.4415 179.029C98.7425 179.029 98.9864 179.254 98.9864 179.532C98.9864 179.81 98.7425 180.035 98.4415 180.035C95.7334 180.035 93.538 182.062 93.538 184.561C93.538 184.839 93.2941 185.064 92.9932 185.064C92.6923 185.064 92.4484 184.839 92.4484 184.561C92.4484 182.062 90.253 180.035 87.5448 180.035C87.2439 180.035 87 179.81 87 179.532C87 179.254 87.2439 179.029 87.5448 179.029C90.253 179.029 92.4484 177.003 92.4484 174.503C92.4484 174.225 92.6923 174 92.9932 174ZM92.9932 176.811C92.3954 178.011 91.3456 178.98 90.0451 179.532C91.3456 180.084 92.3954 181.053 92.9932 182.254C93.591 181.053 94.6408 180.084 95.9413 179.532C94.6408 178.98 93.591 178.011 92.9932 176.811Z"
        fill="#BCA371"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.5 50C198.726 50 198.909 50.1832 198.909 50.4091C198.909 52.4425 200.557 54.0909 202.591 54.0909C202.817 54.0909 203 54.2741 203 54.5C203 54.7259 202.817 54.9091 202.591 54.9091C200.557 54.9091 198.909 56.5575 198.909 58.5909C198.909 58.8168 198.726 59 198.5 59C198.274 59 198.091 58.8168 198.091 58.5909C198.091 56.5575 196.443 54.9091 194.409 54.9091C194.183 54.9091 194 54.7259 194 54.5C194 54.2741 194.183 54.0909 194.409 54.0909C196.443 54.0909 198.091 52.4425 198.091 50.4091C198.091 50.1832 198.274 50 198.5 50ZM198.5 52.2864C198.051 53.2629 197.263 54.0511 196.286 54.5C197.263 54.9489 198.051 55.7371 198.5 56.7136C198.949 55.7371 199.737 54.9489 200.714 54.5C199.737 54.0511 198.949 53.2629 198.5 52.2864Z"
        fill="#BCA371"
      />
      <g filter="url(#filter4_f_390_1785)">
        <path
          d="M140.617 160C140.617 164.137 137.054 167.617 132.5 167.617C127.946 167.617 124.383 164.137 124.383 160C124.383 155.863 127.946 152.383 132.5 152.383C137.054 152.383 140.617 155.863 140.617 160Z"
          stroke="#EFD6A6"
          strokeWidth="2.76609"
        />
      </g>
      <g filter="url(#filter5_b_390_1785)">
        <path
          d="M108.168 130.256C108.168 145.733 95.621 158.28 80.1435 158.28C64.6661 158.28 52.1191 145.733 52.1191 130.256C52.1191 114.778 64.6661 102.231 80.1435 102.231C95.621 102.231 108.168 114.778 108.168 130.256Z"
          fill="#F6DFB4"
          fillOpacity="0.5"
        />
      </g>
      <g filter="url(#filter6_d_390_1785)">
        <path
          d="M53.6403 146.56C54.8283 145.604 56.5666 145.791 57.5229 146.979C58.4791 148.168 58.2912 149.906 57.1032 150.862L45.7761 159.979C44.5881 160.936 42.8498 160.748 41.8936 159.56C40.9373 158.372 41.1252 156.633 42.3132 155.677L53.6403 146.56Z"
          fill="#D7BA83"
        />
      </g>
      <g filter="url(#filter7_d_390_1785)">
        <path
          d="M112.652 129.882C112.652 148.042 97.9301 162.764 79.7699 162.764C61.6097 162.764 46.8879 148.042 46.8879 129.882C46.8879 111.722 61.6097 97 79.7699 97C97.9301 97 112.652 111.722 112.652 129.882ZM54.2763 129.882C54.2763 143.962 65.6902 155.376 79.7699 155.376C93.8496 155.376 105.263 143.962 105.263 129.882C105.263 115.802 93.8496 104.388 79.7699 104.388C65.6902 104.388 54.2763 115.802 54.2763 129.882Z"
          fill="url(#paint0_linear_390_1785)"
        />
      </g>
      <g filter="url(#filter8_d_390_1785)">
        <path
          d="M39.881 152.84C42.1316 151.028 45.4246 151.384 47.2362 153.635L48.642 155.381C50.4535 157.632 50.0976 160.925 47.847 162.737L19.0411 185.923C16.7904 187.734 13.4974 187.379 11.6858 185.128L10.2801 183.381C8.46852 181.131 8.82444 177.838 11.0751 176.026L39.881 152.84Z"
          fill="#BB9D63"
        />
      </g>
      <g filter="url(#filter9_f_390_1785)">
        <circle cx="71.6101" cy="86.6101" r="3.2271" stroke="#EFD6A6" strokeWidth="2.76609" />
      </g>
      <g filter="url(#filter10_f_390_1785)">
        <circle cx="110.728" cy="138.952" r="4.14913" fill="#EFD6A6" />
      </g>
      <defs>
        <filter
          id="filter0_f_390_1785"
          x="160.312"
          y="27.3119"
          width="20.2847"
          height="20.2847"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.84406" result="effect1_foregroundBlur_390_1785" />
        </filter>
        <filter
          id="filter1_d_390_1785"
          x="98.9356"
          y="31.3119"
          width="86.1287"
          height="86.1287"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.37624" />
          <feGaussianBlur stdDeviation="5.53218" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_390_1785" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_390_1785" result="shape" />
        </filter>
        <filter
          id="filter2_d_390_1785"
          x="121.936"
          y="92.3119"
          width="86.1287"
          height="86.1287"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.37624" />
          <feGaussianBlur stdDeviation="5.53218" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_390_1785" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_390_1785" result="shape" />
        </filter>
        <filter
          id="filter3_d_390_1785"
          x="60.9356"
          y="77.3119"
          width="86.1287"
          height="86.1287"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.37624" />
          <feGaussianBlur stdDeviation="5.53218" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_390_1785" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_390_1785" result="shape" />
        </filter>
        <filter
          id="filter4_f_390_1785"
          x="120.234"
          y="148.234"
          width="24.5322"
          height="23.5322"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.38304" result="effect1_foregroundBlur_390_1785" />
        </filter>
        <filter
          id="filter5_b_390_1785"
          x="48.3826"
          y="98.4949"
          width="63.522"
          height="63.522"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.86829" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_390_1785" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_390_1785" result="shape" />
        </filter>
        <filter
          id="filter6_d_390_1785"
          x="32.3154"
          y="142.96"
          width="34.7857"
          height="32.576"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.97853" />
          <feGaussianBlur stdDeviation="4.4839" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_390_1785" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_390_1785" result="shape" />
        </filter>
        <filter
          id="filter7_d_390_1785"
          x="37.9201"
          y="94.0107"
          width="83.6995"
          height="83.6995"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.97853" />
          <feGaussianBlur stdDeviation="4.4839" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_390_1785" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_390_1785" result="shape" />
        </filter>
        <filter
          id="filter8_d_390_1785"
          x="0.155977"
          y="148.694"
          width="58.6102"
          height="53.3314"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.97853" />
          <feGaussianBlur stdDeviation="4.4839" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_390_1785" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_390_1785" result="shape" />
        </filter>
        <filter
          id="filter9_f_390_1785"
          x="63.3119"
          y="78.3119"
          width="16.5965"
          height="16.5965"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.84406" result="effect1_foregroundBlur_390_1785" />
        </filter>
        <filter
          id="filter10_f_390_1785"
          x="102.891"
          y="131.115"
          width="15.6746"
          height="15.6746"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.84406" result="effect1_foregroundBlur_390_1785" />
        </filter>
        <linearGradient
          id="paint0_linear_390_1785"
          x1="61.8608"
          y1="162.464"
          x2="95.0539"
          y2="97"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BFA168" />
          <stop offset="0.555113" stopColor="#836731" />
          <stop offset="1" stopColor="#927235" />
        </linearGradient>
      </defs>
    </svg>
  );
};
