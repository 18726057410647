/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useState } from 'react';
import { breakpoints } from '../../componentUI';

const DEFAULT_VALUES_SCREENS = {
  isMobile: false,
  isTablet: false,
  isDesktopSNew: false,
  isDesktopS: false,
  isDesktop: false,
  isDesktop1440: false,
  isDesktop1600: false,
  isMega: false,
};

const SCREENS = {
  isMobile: 'isMobile',
  isTablet: 'isTablet',
  isDesktopSNew: 'isDesktopSNew',
  isDesktopS: 'isDesktopS',
  isDesktop: 'isDesktop',
  isDesktop1440: 'isDesktop1440',
  isDesktop1600: 'isDesktop1600',
  isMega: 'isMega',
} as const;

const QUERIES = {
  mobile: `(max-width: ${breakpoints.tablet}px)`,
  tablet: `(max-width: ${breakpoints.desktopS}px)`,
  tabletNew: `(max-width: ${breakpoints.desktopSNew}px)`,
  desktopS: `(max-width: ${breakpoints.desktop}px)`,
  desktop: `(max-width: ${breakpoints.desktop1440}px)`,
  desktop1440: `(max-width: ${breakpoints.desktop1600}px)`,
  desktop1600: `(max-width: ${breakpoints.mega}px)`,
  mega: `(min-width: ${breakpoints.mega}px)`,
};

const mediaQueriesList = (queries: { [key: string]: string }) => {
  return Array.from(Object.values(queries)).map((query) => {
    return matchMedia(query);
  });
};

const getValuesMatches = (mql: Array<MediaQueryList>) => {
  return mql.map((elementMQL) => {
    return elementMQL.matches;
  });
};

export const useMatchMedia = () => {
  const [values, setValues] = useState(getValuesMatches(mediaQueriesList(QUERIES)));

  useLayoutEffect(() => {
    const handler = () => setValues(getValuesMatches(mediaQueriesList(QUERIES)));

    mediaQueriesList(QUERIES).forEach((mql) => {
      return mql.addEventListener('change', handler);
    });

    return () =>
      mediaQueriesList(QUERIES).forEach((mql) => {
        return mql.removeEventListener('change', handler);
      });
  }, []);

  return Object.keys(SCREENS).reduce<Record<keyof typeof SCREENS, boolean>>((acc, screen, index) => {
    return { ...acc, [screen]: values[index] };
  }, DEFAULT_VALUES_SCREENS);
};
