export const darkenHex = (color: string, amount: number) => {
  // check input return color if smth wrong
  if (color.length !== 7 || color[0] !== '#') {
    return color;
  }
  if (amount < 0 || amount > 1) {
    return color;
  }

  // convert color to rgb
  const hex = color.slice(1);
  const [r, g, b] = [0, 2, 4].map((i) => parseInt(hex.slice(i, i + 2), 16));

  // darken color
  const newR = Math.floor(r * (1 - amount));
  const newG = Math.floor(g * (1 - amount));
  const newB = Math.floor(b * (1 - amount));

  // convert back to hex
  const newHex = [newR, newG, newB]
    .map((x) => Math.max(0, x).toString(16))
    .map((x) => (x.length === 1 ? `0${x}` : x))
    .join('');

  return `#${newHex}`;
};
