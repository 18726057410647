import React from 'react';
import styled from 'styled-components';
import { TAG_NAME } from '../../constants';
import { SIZE_STYLE, TITLE_SIZE, TITLE_WEIGHT } from './constants';

type PropsTitle = React.HTMLAttributes<HTMLElement> & {
  colorText: string;
  weight: string;
  size: keyof typeof TITLE_SIZE;
  tag?: keyof JSX.IntrinsicElements;
  margins: [number, number, number, number] | [number, number, number] | [number, number] | [number];
};

const TitleComponent = styled.h1<PropsTitle>`
  font-family: Montserrat, sans-serif;
  color: ${({ colorText }) => colorText};
  ${({ size }) => SIZE_STYLE[size]};
  ${({ weight }) => `font-weight: ${weight}`};

  margin: ${({ margins }) => margins.map((margin) => `${margin}px `).join('')};
`;

export const Title = ({
  size = TITLE_SIZE.h5,
  colorText = '#000000',
  weight = TITLE_WEIGHT.bold,
  tag = TAG_NAME.p,
  margins = [0],
  children,
  ...otherProps
}: Partial<PropsTitle & { children: React.ReactNode }>) => {
  return (
    <TitleComponent as={tag} colorText={colorText} size={size} weight={weight} margins={margins} {...otherProps}>
      {children}
    </TitleComponent>
  );
};
