export const TAG_NAME = {
  span: 'span',
  p: 'p',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
} as const;

export const Z_INDEX_LAYERS = {
  mainLayer: 0,
  other: 5,
  menusLayer: {
    overlayLayer: 10,
    modalLayer: 20,
  }, // for menu, sidebars and other
  notificationsLayer: {
    overlayLayer: 30,
    modalLayer: 40,
  },
  modalsLayer: {
    overlayLayer: 50,
    modalLayer: 60,
  }, // for modals

  errorsLayer: 100, // for errors, should be always on top level
} as const;
