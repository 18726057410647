import { useState, useEffect } from 'react';
// throttle c learn.js
const throttle = (func, ms) => {
  let isThrottled = false;
  let savedArgs;
  let savedThis;

  function wrapper(...rest) {
    if (isThrottled) {
      savedArgs = rest;
      savedThis = this;
      return;
    }

    func.apply(this, rest);

    isThrottled = true;

    setTimeout(function () {
      isThrottled = false;
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = null;
        savedThis = null;
      }
    }, ms);
  }

  return wrapper;
};

// черновая версия, переписать и расширить на все брекпоинты
const MOBILE = 600;
const DELAY = 50;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE);

  useEffect(() => {
    const handleResize = throttle(() => {
      setIsMobile(window.innerWidth < MOBILE);
    }, DELAY);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};
