import { css, FlattenSimpleInterpolation } from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';

export const TYPE_CHECKBOX = {
  type1: 'type1',
  type2: 'type2',
} as const;

export type checkboxTypes = keyof typeof TYPE_CHECKBOX;

export const CHECKBOX_BODY_STYLE: Record<checkboxTypes, FlattenSimpleInterpolation> = {
  type1: css`
    border: 1px solid ${COLOR_MAP.controls.clickableNormal};
    border-radius: 2px;

    background-color: ${COLOR_MAP.controls.fillNormal};

    &:hover {
      border: 1.2px solid ${COLOR_MAP.controls.clickableHover};
    }
  `,
  type2: css`
    border: 1.2px solid ${COLOR_MAP.controls.disabled};
    border-radius: 2px;
    background-color: ${COLOR_MAP.background.disabled};

    &:hover {
      border: 1.2px solid ${COLOR_MAP.controls.clickableHover};
    }
  `,
};
