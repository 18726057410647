import styled from 'styled-components';

export const VisuallyHiddenInput = styled.input`
  position: absolute;
  height: 1px;
  width: 1px;

  overflow: hidden;

  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);

  white-space: nowrap;
`;
