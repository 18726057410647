import styled from 'styled-components';
import { Picture } from '../../componentUI/complex/Picture';
import { supportHover, truthfulAdaptive } from '../../componentUI/screen';
import { COLOR_MAP } from '../../componentUI/colorsMap';

export const PictureStyled = styled(Picture)<{ hover?: boolean }>`
  border-radius: 5px;

  ${({ hover }) => hover && '&:hover { cursor: pointer; box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); }'}
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const IndexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  align-self: stretch;

  ${truthfulAdaptive.maxWidth.tablet} {
    flex-direction: column;
    gap: 16px;
  }
`;

export const TitleAndCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${truthfulAdaptive.maxWidth.tablet} {
    align-self: flex-start;
    flex-wrap: wrap;
  }
`;

export const ButtonCard = styled.label<{ disabled: boolean; isGray?: boolean; marginBottom?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px;
  width: 100%;

  border: 2px solid ${COLOR_MAP.controls.clickableNormal};
  border-radius: 2px;
  user-select: none;
  background-color: transparent;
  color: ${COLOR_MAP.controls.clickableNormal};
  text-decoration: none;
  text-transform: uppercase;

  font-style: normal;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;

  font-family: Montserrat, sans-serif;
  font-weight: 700;

  & path {
    stroke: ${COLOR_MAP.controls.clickableNormal};
  }

  ${supportHover} {
    &:hover {
      color: ${COLOR_MAP.controls.clickableHover};
      border: 2px solid ${COLOR_MAP.controls.clickableHover};

      & path {
        stroke: ${COLOR_MAP.controls.clickableHover};
      }
    }
  }

  &:active {
    color: ${COLOR_MAP.controls.clickablePressed};
    border: 2px solid ${COLOR_MAP.controls.clickablePressed};

    & path {
      stroke: ${COLOR_MAP.controls.clickablePressed};
    }
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover {
    color: ${COLOR_MAP.controls.clickableDisabled};
    border: 2px solid ${COLOR_MAP.controls.clickableDisabled};
    cursor: not-allowed;

    & path {
      stroke: ${COLOR_MAP.controls.clickableDisabled};
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 200px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const NotPhotoLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const PictureButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  display: block;
`;
