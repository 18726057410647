import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';
import { adaptive } from '../../screen';

export const HrVertical = styled.div<{ height?: string }>`
  ${({ height }) => (height ? `height: ${height};` : 'height: 20px;')}

  width: 2px;
  background-color: ${COLOR_MAP.text.lightGrey};

  ${adaptive.maxWidth.mobile} {
    display: none;
  }
`;
