import React from 'react';
import styled from 'styled-components';

const LoaderItem = styled.span<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border: 5px solid #cbad73;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader = ({ width = 80, height = 80 }) => {
  return (
    <Wrapper>
      <LoaderItem width={width} height={height} />
    </Wrapper>
  );
};
