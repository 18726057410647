import React from 'react';

import styled from 'styled-components';
import { PropsShowHideButton } from './types';
import { adaptive, supportHover } from '../../screen';
import { COLOR_MAP } from '../../colorsMap';
import { Text } from '../Text';
import { HrVertical } from '../HrVertical';
import { darkenHex } from '../../utils';

const darkLightBlue = darkenHex(COLOR_MAP.accent.lightBlue, 0.2);
const darkenLightBlue = darkenHex(darkLightBlue, 0.2);

const ButtonComponent = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 20px;
  gap: 16px;

  border: 2px solid ${COLOR_MAP.accent.lightBlue};
  border-radius: 6px;

  user-select: none;
  background-color: transparent;

  text-decoration: none;

  cursor: pointer;

  ${adaptive.maxWidth.mobile} {
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
  }

  ${supportHover} {
    &:hover {
      border: 2px solid ${darkLightBlue};
    }
  }

  &:active {
    border: 2px solid ${darkenLightBlue};
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover {
    border: 2px solid ${COLOR_MAP.controls.clickableDisabled};

    cursor: not-allowed;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  & path {
    stroke: ${COLOR_MAP.accent.lightBlue};
  }
`;

export const SecondButtonShowHide = ({ iconText, text, children, ...props }: PropsShowHideButton) => {
  return (
    <ButtonComponent {...props}>
      <IconWrapper>
        {children}
        <Text colorText={COLOR_MAP.accent.lightBlue}>{iconText}</Text>
      </IconWrapper>
      <HrVertical />
      <Text>{text}</Text>
    </ButtonComponent>
  );
};
