import React, { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from 'react';
import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../componentUI/simple/Text';
import { getInfoPhoto, uploadPhoto } from '../requests';
import { PhotoInfo, ResponseGetPhotoList, Status } from '../types';
import { Loader } from '../../componentUIOld/Loader';
import { CardComment } from '../components/CardComment';
import {
  ButtonCard,
  ButtonsWrapper,
  CardContainer,
  IndexContainer,
  LoaderWrapper,
  NotPhotoLabel,
  PictureButton,
  PictureStyled,
  TitleAndCommentContainer,
  UploadContainer,
} from './styled';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../componentUI/simple/Title';
import { DownloadIcon } from '../../common/icons/SvgInline/SvgIcons_2';
import { Checkbox } from '../../componentUI/simple/Checkbox';
import { TertiaryButton } from '../../componentUI/simple/Button';
import { HelpCircleIcon } from '../../common/icons/SvgInline/SvgIcons_11';
import { useStyledModal } from '../../hooks/useStyledModal/useStyledModal';
import { ViewingIndexPhotos } from '../ViewingIndexPhotos';

type Props = ResponseGetPhotoList & {
  setPhotosUploaded: Dispatch<
    SetStateAction<Record<string, { name: string; value: string; is_unable_to_provide: boolean }[]>>
  >;
  setLastIdSlot: (idSlot: number) => void;
  idSlot: number;
  examples: string[] | undefined;
  isRequired?: boolean;
};

const UNCLEAR_TEXT_MAP: Record<string, string> = {
  flashlight: 'use flashlight',
  'zoom in': 'zoom in on the detail',
  'low quality': 'use a better-quality camera',
  focus: 'focus your camera on the detail',
  'better lighting': 'take the picture in a well-lit environment',
  'no flashlight': "don't use the flashlight",
  reposition: 'move your camera closer to the needed detail',
};

// eslint-disable-next-line camelcase
export const Card = ({
  index,
  url,
  icon_url,
  index_back_name,
  problem,
  problem_description,
  setPhotosUploaded,
  setLastIdSlot,
  url_photo_submitted,
  idSlot,
  isRequired,
  comment,
  examples,
  photo_marked,
}: Props) => {
  const [Modal, toggleModal] = useStyledModal();
  const [ModalPicture, toggleModalPicture] = useStyledModal();

  const [statusUploading, setStatusUploading] = useState(Status.None);
  const [countUpload, setCountUpload] = useState(0);

  const refFile = useRef(null);
  const [hasNotPhoto, setHasNotPhoto] = useState(false);

  const changeImage = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const reader = new FileReader();
    const file = evt?.target?.files?.[0];

    if (!file?.name.toLowerCase().match(/\.(jpg|jpeg|png|heic|heif)$/)) {
      // TODO: add readable error for user
      return;
    }

    reader.onloadend = async () => {
      try {
        setStatusUploading(Status.Loading);
        const resUpload = await uploadPhoto<{ file: string }>({
          UPLOADCARE_PUB_KEY: 'ad3d10f764f69a907860',
          UPLOADCARE_STORE: 'auto',
          file,
          file_name: file.name,
        });

        const resInfo = await getInfoPhoto<PhotoInfo>('ad3d10f764f69a907860', resUpload.file);

        setHasNotPhoto(false);
        setPhotosUploaded((prevState) => {
          if (prevState.hasOwnProperty(idSlot.toString())) {
            return {
              ...prevState,
              [idSlot]: [
                ...prevState[idSlot].filter((item) => !item.is_unable_to_provide),
                {
                  name: index_back_name,
                  value: `https://ucarecdn.com/${resInfo.file_id}/`,
                  is_unable_to_provide: false,
                },
              ],
            };
          }

          return {
            ...prevState,
            [idSlot]: [
              {
                name: index_back_name,
                value: `https://ucarecdn.com/${resInfo.file_id}/`,
                is_unable_to_provide: false,
              },
            ],
          };
        });
        setCountUpload((prevState) => prevState + 1);

        setStatusUploading(Status.Done);
      } catch {
        setStatusUploading(Status.Error);
      }
    };
    reader.readAsDataURL(file);

    if (refFile.current) {
      // чистим для того что бы клиенты могли грузить одинаковые фотки
      // @ts-ignore
      refFile.current.value = null;
    }
  };

  const deleteUploadPhoto = () => {
    setPhotosUploaded((prevState) => {
      const result = {
        ...prevState,
      };

      delete result[idSlot];

      return result;
    });
    setStatusUploading(Status.None);
    setCountUpload(0);
  };

  const toggleHasNotPhoto = () => {
    setHasNotPhoto((prevState) => !prevState); // Toggle the state value
    setPhotosUploaded((prevState) => {
      setLastIdSlot(idSlot);
      return {
        ...prevState,
        [idSlot]: [{ name: index_back_name, value: '', is_unable_to_provide: true }],
      };
    });
  };

  const isUnclear = problem?.includes('Unclear');
  const isMissing = problem?.includes('Missing');

  let urlPicture = url;

  if (isUnclear) {
    if (photo_marked) {
      urlPicture = photo_marked;
    } else {
      urlPicture = url_photo_submitted || url;
    }
  }

  if (isMissing) {
    urlPicture = icon_url || url;
  }

  const additionalText =
    isUnclear &&
    problem_description
      ?.replaceAll('[', '')
      .replaceAll(']', '')
      .replaceAll('"', '')
      .replaceAll('\\', '')
      .split(',')
      .reduce((acc, text, index) => {
        const valueOnMap = UNCLEAR_TEXT_MAP[text.trim().toLowerCase()];
        if (valueOnMap) {
          if (index === 0) {
            return `: ${valueOnMap}`;
          }

          return `${acc}, ${valueOnMap}`;
        }
        return acc;
      }, '');

  const isLoading = statusUploading === Status.Loading;

  // const testExamples = ['https://placehold.co/560x560', 'https://placehold.co/880x320', 'https://placehold.co/320x880'];

  return (
    <>
      <CardContainer>
        <IndexContainer>
          <TitleAndCommentContainer>
            <Title size={TITLE_SIZE.h5} weight={TITLE_WEIGHT.normal}>
              {index}
            </Title>
            {problem && (
              <Title size={TITLE_SIZE.h6} weight={TITLE_WEIGHT.regular}>
                {problem}
                {additionalText}
              </Title>
            )}

            <CardComment comment={comment} />

            {examples && examples.length > 0 && (
              <>
                <Modal onClose={toggleModal} isPhoto>
                  <ViewingIndexPhotos FilesImages={examples} />
                </Modal>
                <TertiaryButton onClick={toggleModal}>
                  <HelpCircleIcon />
                  See image sample
                </TertiaryButton>
              </>
            )}

            {isRequired && (
              <>
                <NotPhotoLabel>
                  <Checkbox
                    label="I don't have a photo of this detail"
                    checked={hasNotPhoto}
                    onChange={toggleHasNotPhoto}
                  />
                </NotPhotoLabel>
              </>
            )}
          </TitleAndCommentContainer>

          <UploadContainer>
            <PictureButton onClick={toggleModalPicture}>
              <ModalPicture onClose={toggleModalPicture} isPhoto>
                <ViewingIndexPhotos FilesImages={[urlPicture]} />
              </ModalPicture>
              <PictureStyled url={urlPicture} width={120} height={120} />
            </PictureButton>
            {countUpload === 0 && (
              <ButtonsWrapper>
                {isLoading && (
                  <LoaderWrapper>
                    {isLoading && <Loader width={22} height={22} />}
                    <Text size={TEXT_SIZE.sub} weight={TEXT_WEIGHT.bold}>
                      Uploading
                    </Text>
                  </LoaderWrapper>
                )}
                <ButtonCard disabled={isLoading} marginBottom={isRequired}>
                  <DownloadIcon size="16" />
                  Upload photo
                  <input type="file" ref={refFile} hidden accept=".png,.jpg,.jpeg,.heic,.heif" onChange={changeImage} />
                </ButtonCard>
              </ButtonsWrapper>
            )}
            {countUpload > 0 && (
              <ButtonsWrapper>
                <LoaderWrapper>
                  {isLoading && <Loader width={22} height={22} />}
                  <Text size={TEXT_SIZE.sub} weight={TEXT_WEIGHT.bold}>
                    {isLoading ? 'Uploading' : `${countUpload} photo${countUpload > 1 ? 's' : ''} uploaded`}
                  </Text>
                </LoaderWrapper>
                <ButtonCard disabled={countUpload === 3 || isLoading} marginBottom>
                  + Add more
                  <input
                    id={idSlot.toString()}
                    type="file"
                    ref={refFile}
                    hidden
                    accept=".png,.jpg,.jpeg,.heic,.heif"
                    onChange={changeImage}
                  />
                </ButtonCard>

                <ButtonCard onClick={deleteUploadPhoto} disabled={isLoading}>
                  Remove all
                </ButtonCard>
              </ButtonsWrapper>
            )}
          </UploadContainer>
        </IndexContainer>
      </CardContainer>
    </>
  );
};
