import React, { HTMLAttributes } from 'react';

import styled from 'styled-components';

type Props = HTMLAttributes<HTMLElement> & {
  url: string;
  width: number | string;
  height: number | string;
  alt?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Loader = styled.div<{ height: number | string; width: number | string }>`
  ${({ width }) => {
    if (typeof width === 'string') {
      return `width: ${width};`;
    }
    return width && `width: ${width - 1}px;`;
  }}

  ${({ height }) => {
    if (typeof height === 'string') {
      return `width: ${height};`;
    }
    return height && `width: ${height - 1}px;`;
  }}

  border: 1px rgba(0,0,0,0) dashed;
`;

export const Picture = ({ url, width, alt, height, ...otherProps }: Props) => {
  // пока не требуются шиммеры для картинок - моргало при перерисовке картинок
  // const [isLoading, setIsLoading] = useState(true);
  // const [img, setImg] = useState<HTMLImageElement>();
  //
  // useEffect(() => {
  //   const image = new Image();
  //   image.onload = () => {
  //     setIsLoading(false);
  //     setImg(image);
  //   };
  //   image.src = url;
  //   const complete = img?.complete;
  // }, [url]);
  //
  // if (isLoading) {
  //   return <Loader width={width} height={height} {...otherProps} />;
  // }

  return <img src={url} alt={alt} width={width} height={height} {...otherProps} />;
};
