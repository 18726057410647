import styled from 'styled-components';
import { truthfulAdaptive } from '../componentUI/screen';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  max-width: 970px;
  padding: 0 40px;
  margin: 0 auto 50px;

  ${truthfulAdaptive.maxWidth.tablet} {
    padding: 0 20px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
`;

export const UploadMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
`;

export const UploadTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  width: 100%;
`;

export const PhotosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  padding-bottom: 30px;
  width: 100%;
`;

export const TitleWithTooltipWrapper = styled.div`
  display: flex;
  gap: 10px;
  position: relative;
  flex-direction: column;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

export const SubmitWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

export const WrapperInstructionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
